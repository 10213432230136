@import 'src/styles/helpers';

.clients {
  overflow: hidden;
  width: 100vw;
  padding: 100px 0 124px;

  @include media(tablet) {
    padding: 120px 0;
  }

  @include media(desktopAll) {
    padding: 120px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: auto;
    position: relative;
    z-index: 1;

    @include media(landscape) {
      gap: 64px;
    }

    @include media(portrait) {
      gap: 40px;
    }

    @include media(mobile) {
      gap: 40px;
    }

    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, var(--bg) 2.74%, transparent 54.55%, var(--bg) 100%);
      z-index: 2;
    }
  }
}

.row {
  display: flex;
  justify-content: flex-end;
  gap: 104px;
  transition: none;

  @include media(portrait) {
    gap: 40px;
  }

  @include media(mobile) {
    gap: 30px;
  }

  &_even {
    justify-content: flex-start;
  }
}

.item {
  max-width: 310px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include media(mobile) {
    max-width: 90px;
    min-width: 60px;
  }

  svg, img {
    width: 100%;
    height: auto;
  }
}

.header {
  margin-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .ctaButton {
    display: none;
    flex-shrink: 0;
  }

  @include media(tablet) {
    margin-bottom: 120px;

    .ctaButton {
      display: flex;
    }
  }

  @include media(portrait) {
    gap: var(--margin-lg);
  }

  @include media(desktopAll) {
    margin-bottom: 120px;
    gap: var(--margin-lg);

    .ctaButton {
      display: flex;
    }
  }

  @include media(desktopHuge) {
    margin-bottom: 164px;
  }

  .divider {
    display: block;
    height: 1px;
    @include setProperty(background, var(--secondary-500), var(--secondary-100));
    width: 100%;
  }
}

.ctaButton {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  @include setProperty(border-color, var(--primary-500), var(--primary-900));
  font-family: 'KharkivTone', sans-serif;
  margin: 0 auto;
  font-size: 16px;

  &_mobile {
    margin-top: 40px;

    @include media(tablet) {
      display: none !important;
    }

    @include media(desktopAll) {
      display: none !important;
    }
  }

  @include media(portrait) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media(desktopAll) {
    padding-top: 16px;
    padding-bottom: 14px;
  }
}
