@import 'src/styles/helpers';

.consultation {
  overflow: hidden;
  width: 100vw;
  position: relative;
  padding-top: 72px;

  @include media(landscape) {
    padding-top: 184px;
  }

  @include media(desktopAll) {
    padding-top: 184px;
  }
}

.content {

  &:not(.content_homepage) {
    //padding-top: 100px;
  }

  &_homepage {

    @include media(landscape) {
      padding-right: 120px;
    }

    @include media(desktopAll) {
      padding-right: 140px;
    }

    @include media(desktopSmall) {
      padding-right: 120px;
    }

    @include media(desktop) {
      padding-right: 100px;
    }

    .title, .text {
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }

    .line {
      display: block;
    }
  }
}

.title {
  text-align: center;
}

.text {
  margin-top: 23px;
  margin-bottom: 80px;
  font-size: 14px;
  line-height: 2;
  @include setProperty(font-weight, 300, 500);
  max-width: 410px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @include media(desktopHuge) {
    font-size: 20px;
    max-width: none;
  }

  @include media(desktopSmall) {
    margin-bottom: 64px;
  }

  @include media(portrait) {
    margin-bottom: 112px;
  }
}

.circles {
  display: flex;
}

.circle {
  transition: none;
  @include setProperty(color, var(--primary-500), var(--primary-900));

  &:nth-child(1) {
    opacity: 0.2;
  }

  &:nth-child(2) {
    opacity: 0.3;
  }

  &:nth-child(3) {
    opacity: 0.6;
  }

  svg, img {
    width: 100%;
    height: auto;
  }

  &__border {

    circle {
      @include setProperty(stroke, var(--primary-500), var(--primary-900));
    }
  }
}

.line {
  width: 273px;
  height: 18px;
  position: absolute;
  right: calc(-273px + 100px);
  top: calc(100% + 2px);
  display: none;
}

.form {
  width: 480px;
  margin-left: auto;
  position: relative;
  margin-bottom: 42px;

  @include media(portrait) {
    margin-bottom: 62px;
    margin-left: auto;
    margin-right: auto;
    width: 408px;
  }

  @include media(landscape) {
    margin-bottom: 62px;
  }

  @include media(mobile) {
    width: 100%;
    margin-bottom: 112px;
  }

  @include media(landscape) {
    margin-right: 63px;
  }

  @include media(desktopAll) {
    margin-bottom: 62px;
  }
}

.submitButton {
  position: absolute;
  top: 0;
  right: 40px;
  @include setProperty(color, var(--secondary-300), var(--secondary-200));
  width: 24px;
  height: 24px;
  transition: color 0.3s ease;

  &:hover:not(:disabled) {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @keyframes spin {
    from {
      transform: rotate(0)
    }

    to {
      transform: rotate(360deg)
    }
  }

  .spinner {
    animation: spin 1s infinite linear;
    width: 24px;
    height: 24px;
  }
}


.inputWrap {
  position: relative;

  &:hover {

    .input {
      @include setProperty(border-bottom, 1px solid var(--secondary-200), 1px solid var(--secondary-400));

      &::placeholder {
        @include setProperty(color, var(--secondary-200), var(--secondary-400));
      }
    }

    +.submitButton {
      @include setProperty(color, var(--secondary-200), var(--secondary-400));
    }
  }
}

.input {
  background-color: transparent;
  border: none;
  @include setProperty(border-bottom, 1px solid var(--secondary-300), 1px solid var(--secondary-200));
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.4px;
  padding: 0 24px 12px;
  height: 44px;
  display: block;
  outline: none;
  @include setProperty(color, var(--secondary-50), var(--secondary-400));
  width: 100%;
  @include setProperty(caret-color, var(--primary-500), var(--primary-900));

  &::placeholder {
    @include setProperty(color, var(--secondary-300), var(--secondary-200));
  }

  &:focus {
    @include setProperty(border-bottom, 1px solid var(--secondary-200), 1px solid var(--secondary-400));

    &::placeholder {
      color: transparent !important;
    }
  }
}

.status {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: var(--ls-183);
  order: 1;
  margin-top: var(--margin-xs);
  position: absolute;
  top: 40px;
  left: 0;
  max-width: 70%;

  @include media(desktopAll) {
    max-width: 80%;
  }

  &.success {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &.error {
    @include setProperty(color, var(--dark-error), var(--light-error));
  }
}
