@import "src/styles/helpers";

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: white;
    padding-top: 72px;

    .pageButton {
        position: relative;
        border-radius: 36px;
        padding: 10px;
        width: 44px;
        height: 44px;

        .pageNumber {
            position: absolute;
            width: 42px;
            height: 42px;
            border-radius: 36px;
            top: 1px;
            left: 1px;
            text-align: center;
            align-content: center;
            @include setProperty(color, var(--secondary-200), var(--secondary-500));
            @include setProperty(background, var(--dark-bg), var(--light-bg));
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.183px;
        }

        &.active {
            @include setProperty(background, linear-gradient(160deg, transparent 20%, #f6fffeb5 80%, #98fff0 90%), linear-gradient(160deg, transparent 20%, #f6fffeb5 40%, #cccccc 90%));
            
            .pageNumber {
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
                font-weight: 600;
            }
        }
    }
}