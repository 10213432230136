@import 'src/styles/helpers';

.factsSection {
    position: relative;
}

.factsBlock {
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media(portrait) {
        gap: 96px;
    }
    
    @include media(desktopAll) {
        padding: 120px 0;
    }

    .factsTitle {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        max-width: 660px;

        @include media(landscape) {
            font-size: 40px;
            line-height: 48px;
        }

        mark {
            color: var(--primary-500);
        }

    }

    .facts {
        display: grid;
        grid-template-rows: auto;

        @include media(notMobile) {
            grid-template-rows: none;
            grid-template-columns: repeat(3, 1fr);
        }

        @include media(portrait) {
            grid-template-rows: none;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 18px;
        }

        .fact {
            display: flex;
            flex-direction: column;
            gap: 24px;   
            padding: 40px 0;

            &:not(:last-child) {
                border-bottom: 1px solid;
                @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));

                @include media(notMobile) {
                    border-bottom: none;
                }

                @include media(landscape) {
                    border-right: 1px solid;
                    @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
                }

                @include media(desktopAll) {
                    border-right: 1px solid;
                    @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
                }
            }

            @include media(notMobile) {
                padding: 40px;
            }

            @include media(portrait) {
                &:first-child {
                    border-right: 1px solid;
                    @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
                }
                    
                &:last-child {
                    grid-column: 1 / span 2;
                    border-top: 1px solid;
                    @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
                }
            }

            .factValue {
                font-size: 40px;
                line-height: 48px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
            }

            .factDescription {
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
                @include setProperty(font-weight, 300, 400);
                opacity: 0.8;

                @include media(landscape) {
                    font-size: 16px;
                    line-height: 32px;
                }
            }
        }
    }
}

.casesFactsBg {
    position: absolute;
    top: -150px;
    right: -320px;

    @include media(portrait) {
        right: -240px;
    }

    @include media(landscape) {
        right: -320px;
    }

    @include media(desktopAll) {
        right: -200px;
    }
  
    .largestCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 0;
      top: 0;
    }
  
    .mediumCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 114px;
      top: 16px;
    }
  
    .smallCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 214px;
      top: 32px;
    }
  
    .smallestCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 278px;
      top: 42px;
    }
  
    .noise {
      position: absolute;
      right: 300px;
      top: 180px;
    }
  
    .planetBig {
      position: absolute;
      top: 6px;
      right: 290px;
    }
  
    .planetSmall {
      position: absolute;
      top: 52px;
      right: 374px;
    }
  }