@import '../../../../styles/helpers';

.case {

  &:nth-child(odd) {
    padding-top: 64px;

    @include media(mobile) {
      padding-top: 0;
    }
  }

  &.alternative-view {
    &:nth-child(odd) {
      padding-top: 0;

      @include media(landscape) {
        padding-top: 80px;
      }

      @include media(desktopAll) {
        padding-top: 80px;
      }
    }
  }
}

.categories {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
  font-family: 'KharkivTone', sans-serif;
  font-size: 14px;
  line-height: 1;

  @include media(desktopHuge) {
    font-size: 20px;
  }
}

.category {
  @include setProperty(color, var(--secondary-200), var(--secondary-300));

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &:active {
    @include setProperty(color, var(--primary-300), var(--primary-700));
  }
}

.image {
  display: block;
  @include imgCover;
  position: relative;
  height: 414px;
  overflow: hidden;

  &:hover {

    img {
      transform: scale(1.05);
    }
  }

  &.withCustomCursor {
    cursor: none;
  }

  @include media(desktopHuge) {
    height: 568px;
  }

  @include media(landscape) {
    height: 312px;
  }

  @include media(portrait) {
    height: 312px;
  }

  @include media(mobile) {
    height: 312px;
  }

  &.alternative-view {
    height: auto;
    width: 327px;
    aspect-ratio: 3 / 2;

    @include media(portrait) {
      width: 640px;
    }

    @include media(landscape) {
      width: 408px;
    }

    @include media(desktopAll) {
      width: 488px;
    }

    @include media(desktopBig) {
      width: 608px;
    }

    @include media(desktopHuge) {
      width: 608px;
    }
  }

  img {
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease;
  }
}

.caseInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  .caseProject {
    text-transform: uppercase;
    color: var(--primary-900);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.183px;
  }

  .caseTitle {
    font-family: 'KharkivTone', sans-serif;
    font-size: 24px;
    line-height: 32px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }

  .caseDescription {
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
    letter-spacing: 0.183px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }
}