@mixin pageHeaderPadding {
  padding-top: 72px;

  @include media(desktopAll) {
    padding-top: 96px;
  }

  @include media(desktopHuge) {
    padding-top: 112px;
  }
}
