@import "src/styles/helpers";

.customCursorWrapper {
    position: relative;
    // cursor: none !important;

    &:not(.disabled) {
        cursor: none !important;
    }
}

.customCursor {
    position: fixed;
    z-index: 99;
    width: 96px;
    height: 96px;
    border-radius: 200%;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-family: 'KharkivTone', sans-serif;
    text-align: center;
    align-content: center;
    pointer-events: none;
    display: none;

    @include media(desktopAll) {
        display: block;
    }

    &.light {
        @include setProperty(color, var(--secondary-50), var(--secondary-900));
        @include setProperty(background-color, var(--dark-bg), var(--secondary-50));
    }

    &.dark {
        @include setProperty(color, var(--secondary-900), var(--secondary-50));
        @include setProperty(background-color, var(--secondary-50), var(--dark-bg));
    }
}