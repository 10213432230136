@import 'src/styles/helpers';

.casesFilters {
    position: relative;
    z-index: 2;

    .filters {
        padding-top: 40px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(landscape) {
            flex-direction: row;
        }

        @include media(desktopAll) {
            flex-direction: row;
        }
    }
    
    .filterDropdown {
        position: relative;
        flex: 1 0 0;
        border: 1px solid;
        @include setProperty(border-color, var(--secondary-400), var(--light-line-shape));
        @include setProperty(background, var(--dark-bg), var(--light-bg));
        font-family: 'KharkivTone' sans-serif;
        align-self: stretch;

        @include media(mobile) {
            &:first-child {
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }
    
            &:last-child {
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }

        @include media(portrait) {
            &:first-child {
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }
    
            &:last-child {
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }

        @include media(landscape) {
            &:first-child {
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
            }
        
            &:last-child {
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }

        @include media(desktopAll) {
            &:first-child {
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
            }
        
            &:last-child {
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }

        .dropdownButton {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: space-between;
            width: 100%;
            padding: 24px;
            border-radius: inherit;

            @include media(mobileSmall) {
                padding: 24px 12px;
            }

            .buttonText {
                font-family: 'KharkivTone', sans-serif;
                flex: 1 0 0;
                text-wrap: nowrap;
                text-overflow: ellipsis;

                @include media(mobileSmall) {
                    font-size: 12px;
                }

                mark {
                    color: var(--primary-500);
                }
            }

            svg {
                width: 100%;
                height: 100%;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
            }

            .clearButton {
                height: 24px;
                cursor: pointer;

                svg {
                    position: relative;
                    width: 24px;
                    height: 24px;
                }

            }
            
            .arrowIcon {
                width: 24px;
                height: 24px;
                transition: all ease-in-out 0.5s;
            }

            &:hover {
                @include setProperty(background, var(--secondary-800), var(--secondary-50));
    
                svg {
                    use {
                        color: var(--primary-500); 
                    }   
                } 
            }
        }

        &.active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .dropdownButton {
                color: var(--primary-500);

                .arrowIcon {
                    transform: rotate(180deg);
                }

                &:hover {
                    @include setProperty(background, rgba(107, 216, 199, 0.05), rgba(60, 191, 166, 0.10));
                }
            }
        }

        &.filled {
            .dropdownButton {
                .buttonText {
                    @include setProperty(color, var(--secondary-50), var(--secondary-500));
                }
            }
        }

        .dropdownItemsWrapper {
            position: absolute;
            padding: 14px;
            width: 100%;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            @include setProperty(background, var(--dark-bg), var(--light-bg));
            border: 1px solid;
            @include setProperty(border-color, var(--secondary-400), var(--light-line-shape));
            z-index: 3;
        }

        .dropdownItems {
            padding: 4px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: flex-start;
            border-radius: 0;
            width: 100%;
            max-height: 300px;
            overflow: auto;

            .item {
                display: flex;
                align-items: center;
                align-self: stretch;
                gap: 12px;
                font-weight: 300;
                @include setProperty(color, var(--secondary-100), var(--secondary-400));

                .checkIcon {
                    width: 24px;
                    height: 24px;
                    border: 1px solid;
                    border-radius: 2px;
                    @include setProperty(border-color, var(--dark-line-accent), var(--light-line-accent));

                    use {
                        @include setProperty(color, var(--primary-500), var(--light-line-accent));
                        opacity: 0;
                    }
                }

                &:hover {
                    @include setProperty(color, var(--secondary-50), var(--secondary-500));

                    .checkIcon {
                        border-color: var(--primary-500);
                    }
                }

                &.selected {
                    @include setProperty(color, var(--secondary-50), var(--secondary-500));

                    .checkIcon {
                        @include setProperty(border-color, var(--primary-500), var(--light-line-accent));

                        use {
                            opacity: 1;
                        }
                    }
                }

                &.disabled {
                    position: relative;
                    @include setProperty(color, var(--secondary-400), var(--secondary-100));

                    .checkIcon {
                        @include setProperty(border-color, var(--secondary-400), var(--secondary-100));
                        
                        use {
                           opacity: 0;
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        width: 16px;
                        height: 16px;
                        @include setProperty(background, var(--secondary-400), var(--secondary-100));
                        border-radius: 2px;
                        z-index: 1000;
                    }
                }
            }
        }

        ::-webkit-scrollbar {
            margin-inline-end: 10px;
            width: 4px;
            background: var(--dark-line-low-accent);
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--dark-line-accent);
        }
    }

    button {
        font-size: 14px;
        line-height: 20px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));

        @include media(desktopAll) {
            font-size: 16px;
            line-height: 24px;
        }

        svg {
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }
    }
}

.selectedFilters {
    position: relative;
    margin-top: 24px;
    height: 48px;

    @include media(mobile) {
        padding: 0 !important;
    }

    @include media(portrait) {
        padding: 0 !important;
    }

    .selectedFiltersContent {
        position: relative;

        &.blockView {
            .sliderWrapper {
                margin: 0;
            }

            .sliderButton {
                display: none;
            }
        }

        @include media(mobile) {
            .sliderWrapper {
                margin: 0;
            }

            .sliderButton {
                display: none;
            }
        }

        @include media(portrait) {
            .sliderWrapper {
                margin: 0;
            }

            .sliderButton {
                display: none;
            }
        }

        .sliderWrapper {
            @include media(landscape) {
                margin: 0 90px;
                overflow: clip;
            }

            @include media(desktopAll) {
                margin: 0 90px;
                overflow: clip;
            }
        }
    
        .sliderButton {
            @include setProperty(background, var(--dark-bg), var(--light-bg));
            @include setProperty(border-color, rgba(107, 216, 199, 0.10), var(--secondary-50));
            padding: 12px 24px;
            width: auto;
            height: auto;
            border-radius: 56px;
    
            &.prev {
                left: 0;
            }
    
            &.next {
                right: 0;
            }
    
            svg {
                width: 24px;
                height: 24px;
            }
    
            &:hover {
                @include setProperty(background, var(--secondary-800), var(--secondary-50));
            }
        }
    }
}

.selectedFilter {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 12px 24px;
    border-radius: 60px;
    @include setProperty(background, var(--secondary-800), var(--secondary-50));
    width: fit-content;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.183px;

    &:hover {
        @include setProperty(background, var(--secondary-600), var(--secondary-100));
    }

    button { 
        height: 16px;
        width: 16px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));

        .removeIcon {
            width: 16px;
            height: 16px;
        }
    }
}